import React, { useState } from "react";
import styled from "styled-components";
import applogo from "../images/filmify-logo-bw-nbg.png";
import iphoneimg from "../images/iphone.png";
import applestoreimg from "../images/appstore-logo.svg";
import { motion } from "framer-motion";

function Welcome() {
  const [imageLoading, setImageLoading] = useState(true);
  const imageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay: 0.5, duration: 1.7 } }}
        onLoad={imageLoaded}
        className="column-logo"
      >
        <img className="filmify-logo" src={applogo} alt="filmify-logo"></img>
        <div className="filmify-logo-description">
          Time travel to film era with a touch. Choose among exclusively created
          film filters.
        </div>
        <div className="app-links">
          <a
            href="https://apps.apple.com/vn/app/filmify-film-filters/id6502541032?platform=iphone"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="appstore-logo"
              src={applestoreimg}
              alt="applestore-logo"
            />
          </a>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay: 1.3, duration: 1.9 } }}
        onLoad={imageLoaded}
        className="column-phone"
      >
        <img className="iphone" src={iphoneimg} alt="iphone-logo"></img>
      </motion.div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5% 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 2% 0;
  }

  .column-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .column-phone {
    width: 50%;
    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
      width: 100%;
      margin-top: 5%;
    }
  }

  .filmify-logo {
    width: 55%;
    height: auto;
    margin-bottom: 2%;

    @media (max-width: 768px) {
      width: 70%;
    }
  }

  .filmify-logo-description {
    text-align: center;
    margin: 2% 5%;
    font-size: 1.2rem;
    font-family: "IBM Plex Sans", sans-serif;

    @media (max-width: 768px) {
      margin: 2% 10%;
      font-size: 1.5rem;
    }

    @media (max-width: 480px) {
      margin: 2% 5%;
      font-size: 1.2rem;
    }
  }

  .app-links {
    display: flex;
    justify-content: center;
    margin-top: 2%;

    @media (max-width: 768px) {
      margin-top: 3%;
    }
  }

  .appstore-logo {
    width: 7vw;

    @media (max-width: 768px) {
      width: 20vw;
    }

    @media (max-width: 480px) {
      width: 25vw;
    }
  }

  .iphone {
    width: 80%;
    height: auto;

    @media (max-width: 1024px) {
      width: 60%;
    }

    @media (max-width: 768px) {
      width: 70%;
    }
  }
`;

export default Welcome;
