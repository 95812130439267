import React from "react";
import Home from "./Home";
function Pages() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default Pages;
