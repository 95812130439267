import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const EmailForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const validateForm = () => {
    const isValidName = name.trim() !== "";
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()); // Regex for email validation
    const isValidMessage = message.trim() !== "";
    setIsFormValid(isValidName && isValidEmail && isValidMessage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_k0j26yb";
    const templateId = "template_j62jmr4";
    const publicKey = "WOgrg5qOpCL4X0JE2";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Filmify app",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setEmail("");
        setMessage("");
        setIsFormValid(false); // Reset form validity after successful submission
        setIsEmailSent(true); // Set email sent status to true
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setIsEmailSent(false); // Set email sent status to false
      });
  };

  return (
    <>
      <StyledTitle>Contact</StyledTitle>
      <StyledDescription>
        If you have any questions or concerns, please feel free to contact us.
      </StyledDescription>
      {isEmailSent ? (
        <SuccessMessage>Email sent successfully!</SuccessMessage>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <StyledInput
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              validateForm(); // Validate form on input change
            }}
          />
          <StyledInput
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateForm(); // Validate form on input change
            }}
          />
          <StyledTextarea
            cols="30"
            rows="10"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              validateForm(); // Validate form on input change
            }}
            placeholder="Your Message"
          ></StyledTextarea>
          <StyledButton type="submit" disabled={!isFormValid}>
            Send Email
          </StyledButton>
        </StyledForm>
      )}
    </>
  );
};

const StyledTitle = styled.h1`
  text-align: center;
  color: #444;
`;

const StyledDescription = styled.p`
  text-align: center;
  color: #444;
  font-size: 18px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  background-color: #444;
  padding: 20px;
  border-radius: 10px;
`;

const StyledInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #f0cf90;
    outline: none;
  }
`;

const StyledTextarea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const StyledButton = styled.button`
  background-color: #999;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0cf90;
  }

  &:disabled {
    background-color: #ccc; /* Disable button color */
    cursor: not-allowed; /* Change cursor */
  }
`;

const SuccessMessage = styled.p`
  text-align: center;
  color: green;
  font-weight: bold;
`;

export default EmailForm;
