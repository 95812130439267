import React from "react";
import Welcome from "../components/Welcome";
import Dashboard from "../components/Dashboard";
import Footer from "../components/Footer";
function Home() {
  return (
    <div>
      <Welcome />
      <Dashboard />
      <Footer />
    </div>
  );
}
export default Home;
