import React from "react";
import styled from "styled-components";
import { GiFilmStrip, GiSandsOfTime } from "react-icons/gi";
import { GrMicrofocus } from "react-icons/gr";
import { SiTheconversation } from "react-icons/si";
import { motion } from "framer-motion";

function Dashboard() {
  return (
    <Grid>
      <motion.div whileHover={{ scale: 1.1 }}>
        <Card>
          <GiFilmStrip />
          <h3>Nostalgic Feeling</h3>
          <p>
            Seeing today's photos with yesterday's touch. Explore different
            films to create different stories.
          </p>
        </Card>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}>
        <Card>
          <GrMicrofocus />
          <h3>Quality Touch of Film</h3>
          <p>Bringing vibrant colors with a great depth of field.</p>
        </Card>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}>
        <Card>
          <GiSandsOfTime />
          <h3>No More Waiting</h3>
          <p>No more waiting for films to develop or shooting within budget.</p>
        </Card>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}>
        <Card>
          <SiTheconversation />
          <h3>Unique Way of Storytelling</h3>
          <p>
            Turn your memories into a unique piece of art. Tell your stories
            authentically.
          </p>
        </Card>
      </motion.div>
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 2rem;
  justify-content: center;
  margin: 0 auto 5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-image: linear-gradient(to right, white, rgb(200, 200, 200));
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 25rem; /* Adjust width */
  height: 20rem; /* Ensure square shape */
  overflow: hidden;

  svg {
    width: 6rem;
    height: 8rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  &:hover {
    background: rgb(100, 100, 100);
    h3,
    p,
    svg {
      color: white;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Dashboard;
