import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Footers>
      <ul>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
      </ul>
      <Copy>
        <p>2024 © filmify</p>
      </Copy>
    </Footers>
  );
}

const Copy = styled.div`
  background-color: rgb(220, 220, 220);
  width: 100%;
  text-align: center;
`;

const Footers = styled.div`
  background-color: rgb(220, 220, 220);
  width: 100%; /* Set width to 100% */
  position: relative;
  padding: 2% 0;

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    color: black;
    font-family: ibm;
  }
`;

export default Footer;
